import React,{useEffect} from 'react'
import { Link,useLocation } from "react-router-dom";

function LeftMenuArea() {
  const location = useLocation();
  //alert(location.pathname)


  return (
    <>
    <div className="col-lg-3 order-1">
      <div className="document-left-menu" style={{position: "static"}}>

        <div className="left-menu-item">
          <Link to="/account/dashboard" className={location.pathname == '/account/dashboard' ? 'accordion-button' : 'accordion-button collapsed'}>Dashboard</Link>
          <Link to="/account/fatturazione" className={location.pathname == '/account/fatturazione' ? 'accordion-button' : 'accordion-button collapsed'}>Fatturazione</Link>

          <hr/>
          
          <a className="accordion-button collapsed" href="/logout">Logout</a>
        </div>
        
  
      </div>
    </div>
  </>
  )
}

export default LeftMenuArea
