import React, { useState } from 'react'
import Breadcrumb from "../../../components/common/BreadcrumbSmaller";
import LeftMenuArea from "../Menu/LeftMenuArea";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function DashboardPage() {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Breadcrumb pageName="Dashboard" pageTitle="Dashboard" />
      <div className="document-section pt-100 pb-100">
        <div className="container-fluid">
            <div className="row">
              <LeftMenuArea />

              <div className="col-lg-9 order-lg-2 order-3">
                <h3 className="document-title mb-70">Ordini</h3>
                <div className="document-middle-area">
                  <div className="document-item">
                    <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Numero ordine</th>
                          <th>Data</th>
                          <th>Stato</th>
                          <th>Importo</th>
                          <th>Dettagli</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>123456</td>
                          <td>01/01/2021</td>
                          <td>Confermato</td>
                          <td>€ 100,00</td>
                          <td><a href="/account/ordine/123456">Dettagli</a></td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </div>
                
                </div>
              </div>
            
            </div>
        </div>
      </div>
    </>
  );
}

export default DashboardPage;
