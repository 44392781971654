import { createContext,useState } from "react";

export const OrderContext = createContext();

export function OrderProvider({ children }){

    const [order, setOrder] = useState({
        currentStep:1,
        product: null,
        extra:[],
        fields:[],
        billing:[],
        total:0,
        orderCreated:false
    });

    return (
        <OrderContext.Provider value={[order, setOrder]}>
            {children}
        </OrderContext.Provider>
    );
}