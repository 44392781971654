import React from "react";
import Header from "../../components/common/Header";
import HomeOneFooter from "../../components/common/InnerPageFooter";

import AboutArea from "./AboutArea";
import BlogArea from "./BlogArea";
import Documentation from "./Documentation";
import KnowledgeBaseArea from "./KnowledgeBaseArea";
import MainBanner from "./MainBanner";
import NewsLetterArea from "./NewsLetterArea";
import SupporForumArea from "./SupporForumArea";
import TestimonialArea from "./TestimonialArea";

function MainLayout() {
  return (
    <>
      <Header />

      <MainBanner />
      <AboutArea />

      <Documentation />
      <TestimonialArea />
      <BlogArea />
      <NewsLetterArea />

      <HomeOneFooter />
    </>
  );
}

export default MainLayout;