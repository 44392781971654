import React, { useContext } from "react";
import {useNavigate} from "react-router-dom";

import { ServiceSearchContext } from "../../contexts/ServiceSearchContext";

function MainBanner() {
  const navigate = useNavigate();
  const [searchServiceName, setSearchServiceName] = useContext(ServiceSearchContext);

  const searchServices = (event) => {
    navigate('/servizi');
  }

  return (
    <>
      <div className="herobanner-area herobanner-design-2">
        <div className="social-left-area">
          
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="hero-content text-center">
                <h1>Cerca il servizio che ti interessa</h1>
                <p>Altro testo da sostituire</p>
                <div className="hero-form">
                  <form onSubmit={(e) => e.preventDefault()} className="form-content">
                    <div className="form-input-area">
                      <input type="text" onChange={(e) => { setSearchServiceName(e.target.value) }} placeholder="Inserisci il nome del documento o del servizio che stai cercando" style={{padding: "19px 100px 19px 30px"}}/>
                    </div>
                  </form>
                  <button onClick={searchServices}>Cerca</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="shape hero-img-2" src={process.env.PUBLIC_URL + "/images/hero-img-2.png"} alt="BannerIMG" />
        <img
          className="shape hero-dot-shape-2"
          src={process.env.PUBLIC_URL + "/images/hero-dot-shape-2.png"}
          alt="BannerIMG"
        />
        <img
          className="shape hero-top-left-home-2"
          src={process.env.PUBLIC_URL + "/images/hero-shape-top-left-home-2.png"}
          alt="BannerIMG"
        />
        <img
          className="shape hero-dot-shape-2"
          src={process.env.PUBLIC_URL + "/images/hero-dot-shape-2.png"}
          alt="BannerIMG"
        />
      </div>
    </>
  );
}

export default MainBanner;
