import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import { ServiceSearchProvider } from "./contexts/ServiceSearchContext";
import { AuthProvider } from './contexts/AuthContext';
import { OrderProvider } from './contexts/OrderContext';

import MainLayout from './layouts/MainLayout';

import Homepage from './pages/Home/Homepage';
import Services from './pages/Services/ServicesPage';
import Service from './pages/Service/ServicePage';
import Contacts from './pages/Contact/Contact';

import Login from './pages/Account/Login';
import Register from './pages/Account/Register';

import DashboardPage from './pages/Account/Dashboard/Dashboard';
import FatturazionePage from './pages/Account/Fatturazione/Fatturazione';


import ProtectedAuthRoute from './ProtectedAuth';

// all css import
import "./index.css"

console.log = console.warn = console.error = () => {};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <AuthProvider>
            <ServiceSearchProvider>
                <OrderProvider>

                    <Routes>
                        <Route index element={<Homepage/>}/>

                        <Route element={<MainLayout/>}>
                            <Route path="/servizi" element={<Services/>}/>
                            <Route path="/servizi/:slug" element={<Service/>}/>
                            <Route path="/contatti" element={<Contacts/>}/>

                            <Route path="/accedi" element={<Login/>}/>
                            <Route path="/registrati" element={<Register/>}/>

                            <Route element={<ProtectedAuthRoute />}>
                                <Route path="/account/dashboard" element={<DashboardPage/>}/>
                                <Route path="/account/fatturazione" element={<FatturazionePage/>}/>
                            </Route>
                            
                        </Route>

                        <Route path="*" element="404"/>
                    </Routes>

                </OrderProvider>
            </ServiceSearchProvider>
        </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

