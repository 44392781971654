import { createContext,useState } from "react";

export const AuthContext = createContext();

export function AuthProvider({ children }){

    const [auth, setAuth] = useState(false);

    const userStored = localStorage.getItem("userInfo");

    if(auth == false){
        setAuth(JSON.parse(userStored));
    }

    return (
        <AuthContext.Provider value={[auth, setAuth]}>
            {children}
        </AuthContext.Provider>
    );
}