import React from 'react'
import { Link } from "react-router-dom";
// images


function InnerPageFooter() {
  const scrollTop = () => window.scrollTo({top:0,behavior: "smooth"})
  return (
    <>
    {/* <!-- Footer Area Start--> */}
    <footer className="footer-area">
      <img
        className="shape hero-botto-footer-left"
        src={process.env.PUBLIC_URL + "/images/bg/footer-circle.png"}
        alt="IMG"
      />
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-sm-12 col-12">
            <div className="footer-wrap">
              <div className="row justify-content-between g-4">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="widget">
                    <div className="title">
                      <h2 className="footer-title">Chi Siamo</h2>
                    </div>
                    <div className="content">
                      <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                   
                    <div className="contact">
                      <p>
                        <Link to={"#"}>info@example.com</Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6">
                  <div className="widget">
                    <div className="title">
                      <h3 className="footer-title">Link Utili</h3>
                    </div>
                    <div className="link">
                      <ul>
                        <li>
                          <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/login`}
                          >
                            Lorem ipsum
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/documents`}
                          >
                            Lorem ipsum
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/documents`}
                          >
                            Lorem ipsum 
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6">
                  <div className="widget">
                    <div className="title">
                      <h3 className="footer-title">Lorem ipsum</h3>
                    </div>
                    <div className="link">
                      <ul>
                        <li>
                          <Link onClick={scrollTop} to={"#"}>
                          Lorem ipsum
                          </Link>
                        </li>
                        <li>
                          <Link onClick={scrollTop} to={"#"}>
                          Lorem ipsum
                          </Link>
                        </li>
                        <li>
                          <Link onClick={scrollTop} to={"#"}>
                          Lorem ipsum
                          </Link>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6">
                  <div className="widget">
                    <div className="title">
                      <h3 className="footer-title">Lorem ipsum dolor sit amet</h3>
                    </div>
                    <div className="link">
                      <ul>
                        <li>
                          <Link onClick={scrollTop} to={"#"}>
                          Lorem ipsum
                          </Link>
                        </li>
                        <li>
                          <Link onClick={scrollTop} to={"#"}>
                          Lorem ipsum
                          </Link>
                        </li>
                        <li>
                          <Link onClick={scrollTop} to={"#"}>
                          Lorem ipsum
                          </Link>
                        </li>
       
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="newsletter-wrap d-flex justify-content-center">
              <h2>Iscriviti alla nostra Newsletter</h2>
              <form action="index.html">
                <input type="text" placeholder="Inserisci la tua email" />
                <i className="bi bi-arrow-right"></i>
              </form>
            </div>
          </div>
        </div>
        <div className="row copy-wrap align-items-center g-4">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="copy-social">
              <ul>
                <li>
                  <a rel="noopener noreferrer" href="https://www.facebook.com/" target="_blank">
                    <i className="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="https://www.twitter.com/" target="_blank">
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="https://www.instagram.com/" target="_blank">
                    <i className="bx bxl-instagram"></i>
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="https://www.pinterest.com/" target="_blank">
                    <i className="bx bxl-pinterest-alt"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="copy-right-area text-lg-end">
              <p className="copy-text">
                Copyright 2022 Rhaven Software
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    {/* <!-- Footer Area End --> */}
  </>
  )
}

export default InnerPageFooter