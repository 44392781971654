import React, { useState,useEffect, useContext }  from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { OrderContext } from "../../contexts/OrderContext";

function ServiceFirstStep(props) {
    const [searchLoading, setSearchLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [extraServices, setExtraServices] = useState([]);
    const [serviceFields, setServiceFields] = useState([]);
    const { serviceInfo } = props;

    const navigate = useNavigate();
    const [order,setOrder] = useContext(OrderContext);

    useEffect(() => {
        handleTotalChange(serviceInfo.id);
    }, [serviceInfo]);

    useEffect(() => {
        if(serviceInfo.id){
            handleTotalChange();
        }
    }, [extraServices]);


    const handleExtraService = (e,id) => {
        if(extraServices.includes(id)){
            setExtraServices(extraServices.filter(item => item !== id));
        }else{
            setExtraServices([...extraServices, id]);
        }
    }
    


    const handleTotalChange = (productId = null) => {
        setSearchLoading(true);
        axios.post('https://api.oggicontrollocasa.it/api/v1/service/price-check',{
            product:(productId == null ? serviceInfo.id : productId),
            extra:JSON.stringify(extraServices)
        }).then((res) => {
            setTotal(res.data.data.total);
            setSearchLoading(false);
        })
    }


    const handleFieldInput = (e,id) => {
        const index = serviceFields.findIndex(item => item.id === id);
        
        if(index === -1){
            setServiceFields([...serviceFields, {id:id,value:e.target.value}]);
        }else{
            serviceFields[index].value = e.target.value;

            setServiceFields(serviceFields);
        }
    }


    const handleNextStep = (e) => {
        e.preventDefault();
        setOrder({
            currentStep:2,
            product: serviceInfo.id,
            extra:extraServices,
            fields:serviceFields,
            total:total
        });
    }



    return (
        <>
            <div className="document-right-area" >
                <form onSubmit={handleNextStep} id="formFirstStep">
                    <div class="p-3 pb-5">
                        {serviceInfo.groups &&
                            serviceInfo.groups.map((item,i) => {
                                return (
                                    <>
                                        <h4>{item.name}</h4>
                                        <div class="row pb-3">
                                        {
                                            item.groups.map((field,x) => {
                                                let mandatory = "";
                                                let mandatoryLabel = "";

                                                if(field.required){
                                                    mandatory = "required";
                                                    mandatoryLabel = "*";
                                                }

                                                return (
                                                    <>
                                                    <div class="col-md-6 pb-2">
                                                        <label style={{color:"black"}}>{field.name}{mandatoryLabel}</label>
                                                                            
                                                        {field.type === "text" ? <input type="text" class="form-control" onChange={event => handleFieldInput(event,field.id)} placeholder={field.name} required={mandatory}/> : "" }
                                                        {field.type === "textarea" ? <textarea class="form-control" onChange={event => handleFieldInput(event,field.id)} placeholder={field.name} required={mandatory}></textarea> : "" }

                                                    </div>
                                                    </>
                                                );
                                            })
                                        }
                                                        
                                        </div>
                                        <hr/>
                                    </>
                                );
                            })

                        }   
                    </div>
                </form>  

                <table class="table">
                    <tbody>
                        {
                            serviceInfo.extra && 
                            serviceInfo.extra.map((item,i) => {

                                if(item.type === "fixed"){
                                    return (
                                        <>
                                            <tr style={{background: "#f5f5f5"}}>
                                                <td>{item.name}</td>
                                                <td>{item.cost} €</td>
                                            </tr>
                                        </>
                                    );
                                }

                                if(item.type === "checkbox"){
                                    return (
                                        <>
                                            <tr style={{background: "#f5f5f5"}}>
                                                <td>{item.name} <input type="checkbox" onChange={event => handleExtraService(event,item.id)}/></td>
                                                <td>{item.cost} €</td>
                                            </tr>
                                        </>
                                    );
                                }

                                return (<></>);
                            })

                        }

                        <tr style={{background: "#f5f5f5"}}>
                            <td>Costo Servizio Base</td>
                            <td>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(serviceInfo.gross_price)}</td>
                        </tr>
                        <tr style={{color:"white",background: "#020613"}}>
                            <td>Totale</td>
                            <td>
                                {searchLoading ? 
                                    <div class="spinner-border" role="status"></div> 
                                    : 
                                    new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(total)
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                            
                <div class="d-grid gap-2 pt-2">
                    <button form="formFirstStep" type="submit" className="btn btn-dark" style={{background: "#08bdaa", borderColor: "#08bdaa",borderTopLeftRadius: "0",borderTopRightRadius: "0"}}>{searchLoading ? <div class="spinner-border" role="status"></div> : "Ordina"}</button>
                </div>
                            
            </div>
        </>
    );
}

export default ServiceFirstStep;
