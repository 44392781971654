import React from "react";

function ServiceDescription(props) {

    const { serviceInfo } = props;

    return (
        <>
            <div className="document-middle-area">
                    
                <div className="doc-item-title">
                    <img src={process.env.PUBLIC_URL + "/images/icons/arrow.svg"} alt="docIMG" />
                    <h4>Cosa offriamo</h4>
                </div>

                <p className="para-font">
                    {serviceInfo.description}
                </p>
                <div className="doc-item-title" style={{marginTop: "40px"}}>
                    <img src={process.env.PUBLIC_URL + "/images/icons/arrow.svg"} alt="docIMG" />
                    <h4>Domande</h4>
                </div>

                <div className="faq-wrap">
                     { serviceInfo.faqs &&
                        serviceInfo.faqs.map((item,i) => {
                            return (
                                <div className="faq-item">
                                    <h5 id={"heading" + i} className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={"#collapse" + i} aria-controls={"collapse" + i}>
                                        {item.question}
                                    </h5>
                                    <div id={"collapse" + i} className="accordion-collapse collapse" aria-labelledby={"heading" + i}>
                                        <div className="faq-body">
                                           {item.answer}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }    
                </div>
            </div>  
        </>
    );
}

export default ServiceDescription;
