import { createContext,useState } from "react";

export const ServiceSearchContext = createContext();

export function ServiceSearchProvider({ children }){

    const [name, setName] = useState("");

    return (
        <ServiceSearchContext.Provider value={[name, setName]}>
            {children}
        </ServiceSearchContext.Provider>
    );
}