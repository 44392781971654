import React, { useState, useContext }  from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";

function LoginPage() {
  const [searchLoading, setSearchLoading] = useState(false);
  const [responseResult, setResponseResult] = useState({type: "none", message: ""});
  const [accountEmail, setAccountEmail] = useState("");
  const [accountPassword, setAccountPassword] = useState("");
  const [auth, setAuth] = useContext(AuthContext);
  const navigate = useNavigate();

    const handleSubmit = (e) => {
      e.preventDefault();
      setSearchLoading(true);
      
      axios.post("https://api.oggicontrollocasa.it/api/v1/login",{
        email:accountEmail,
        password:accountPassword
      }).then((res) => {

        if(res.data.success){
          setResponseResult({type: "success", message: "Accesso effettuato correttamente"});

          const userInfo = {
            email:accountEmail,
            token:res.data.data.token,
          };

          localStorage.setItem("userInfo",JSON.stringify(userInfo));
          setAuth(userInfo);

          setTimeout(() => {
            navigate('/');
          }, 1000);
        }else{
          setResponseResult({type: "error", message: "Accesso non riuscito"});
        }


        setSearchLoading(false);
      });
      

    };


    return (
      <>
        <div className="sign-up mt-100 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-10 col-md-12 col-sm-12 col-12">
                <div className="input-card-wrap input-card-wrap-design-2 text-center ">
                  <div className="input-card-title">
                    {
                      responseResult.type == "error" &&

                      <div class="alert alert-danger" role="alert">
                        {responseResult.message}
                      </div> 
                    }
                    {
                      responseResult.type == "success" &&

                      <div class="alert alert-success" role="alert">
                        {responseResult.message}
                      </div> 
                    }
                    

                    <h2>Accedi</h2>
                    <p>
                      Non hai un account?
                      <Link style={{marginLeft: "10px"}}
                        onClick={() => {
                          window.scrollTo({ top: 0, behaver: "smooth" });
                        }}
                        to={`${process.env.PUBLIC_URL}/registrati`}
                      >
                        Crea un account
                      </Link>
                    </p>
                  </div>
                  <div className="input-card-box mt-100">
                    <form  action="">
                      <input type="email" placeholder="Enter Your Email" onChange={(e) => setAccountEmail(e.target.value)}/>
                      <input id="password-field" type="password" onChange={(e) => setAccountPassword(e.target.value)} className="form-control-pass" name="password" placeholder="Password"/>
                      <i className="bi bi-eye field-icon toggle-password" />
                      
                      
                      {
                        !searchLoading ? 
                        (
                          <button className="mt-50" onClick={handleSubmit}>Login</button>
                        )
                        :
                        (
                          <button className="mt-50" disabled><div class="spinner-border text-dark" role="status"></div></button>
                        )
                        
                      }
                      <Link to={"#"}>Forgot Your Password?</Link>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default LoginPage;
