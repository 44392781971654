import React, { useContext, useState,useEffect }  from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import DocumentationBox from "../../components/common/DocumentationBox";
import { ServiceSearchContext } from "../../contexts/ServiceSearchContext";
import axios from "axios";

function ServicesPage() {
  const [searchServiceName, setSearchServiceName] = useContext(ServiceSearchContext);
  const [searchResult, setSearchResult] = useState([]);
  const [searchLoading, setSearchLoading] = useState(true);
  

  useEffect(() => {
    setSearchLoading(true);
    axios.post('https://api.oggicontrollocasa.it/api/v1/services',{name:searchServiceName}).then((res) => {
      setSearchResult(res.data.data);
      setSearchLoading(false);
   })
  }, [searchServiceName]);

  return (
    <>
      <Breadcrumb pageName={"Ricerca per: "+ (searchServiceName === "" ? "TUTTI" : searchServiceName) } pageTitle="Servizi" />
      <div className="documents-area pt-100 mb-100">
        <div className="container">
          <div className="row g-4 justify-content-center">
            
            { 
              searchLoading ? 
              (
                <div class="spinner-border text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                searchResult.map((item,i) => {
                  return (
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                      <DocumentationBox
                        slug={item.slug}
                        icon="/images/icons/extension.svg"
                        title={item.name}
                        features={item.features}
                      />
                    </div>
                  );
                })
              )
               
            }
            
   

          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesPage;
