import React, { useState,useEffect,useContext }  from "react";
import Breadcrumb from "../../components/common/BreadcrumbSmaller";
import axios from "axios";
import { useParams } from "react-router-dom";
import ServiceDescription from "./ServiceDescription";
import ServiceFirstStep from "./ServiceFirstStep";
import ServiceSecondStep from "./ServiceSecondStep";
import ServiceThirdStep from "./ServiceThirdStep";

import { OrderContext } from "../../contexts/OrderContext";

function ServicePage() {
    const [serviceInfo, setServiceInfo] = useState([]);
    const [order, setOrder] = useContext(OrderContext);
    
    let { slug } = useParams();

    useEffect(() => {
        axios.get('https://api.oggicontrollocasa.it/api/v1/services/' + slug).then((res) => {
            setServiceInfo(res.data.data);
        })
    }, []);
    //{serviceInfo && <ServiceFirstStep serviceInfo={serviceInfo}/>}
    return (
    <>
        <Breadcrumb pageName={serviceInfo.name} pageTitle="Servizio" />
        <div className="document-section pt-100 pb-100">
            <div className="container-fluid">
            <div className="row justify-content-center gy-3">
                
                    <div className="col-lg-7 order-lg-2 order-3">
                        {serviceInfo && <ServiceDescription serviceInfo={serviceInfo}/>}
                    </div>

                    {/* Customizer */}

                    <div className="col-lg-4 order-lg-3 order-2" style={{background: "#fff"}}>

                        {order.currentStep === 1 && serviceInfo && <ServiceFirstStep serviceInfo={serviceInfo}/>}
                        
                        {order.currentStep === 2 && serviceInfo && <ServiceSecondStep serviceInfo={serviceInfo}/>}

                        {order.currentStep === 3 && serviceInfo && <ServiceThirdStep serviceInfo={serviceInfo}/>}

                    </div>
                
                </div>
            </div>
        </div>
    </>
    );
}

export default ServicePage;
