import React from "react";
import Header from "../components/common/Header"
import InnerPageFooter from "../components/common/InnerPageFooter";
import { Outlet } from "react-router-dom";

function MainLayout() {
  return (
    <>
      <Header />
      <Outlet />
      <InnerPageFooter />
    </>
  );
}

export default MainLayout;
