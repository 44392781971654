import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { Outlet,Navigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';

const ProtectedAuthRoute = ({children}) => {
    const [auth] = useContext(AuthContext);
    if (!auth) {
      return <Navigate to='/login' replace />;
    }
  
    return children ? children : <Outlet />;
};
  
export default ProtectedAuthRoute;