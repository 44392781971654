import React from 'react'
import { Link } from 'react-router-dom'

function DocumentationBox(props) {
    const scrollTop = ()=> window.scrollTo({top:0,behavior: "smooth"})
  return (
    <>
     <div className="documentation-box documentation-knowledge-design-2">
        <div className="documentation-card-header">
          <h3>
            <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
              {props.title}
            </Link>
          </h3>
        </div>
        <div className="documentation-content">
          <ul>
            {
              props.features.map((item,i) => {
                return (
                  <li>
                    <i className="bi bi-file-earmark-text" />
                    {item.value}
                  </li>
                );
              })
            }

          </ul>
        </div>
        <div className="btn-wrap documentation-knowledge-design-2-btn-wrap">
          <Link className="documentation-knowledge-btn" onClick={scrollTop} to={`${process.env.PUBLIC_URL}/servizi/${props.slug}`}>
            Visualizza
          </Link>
          <i className="bi bi-arrow-right" />
        </div>
      </div>   
    </>
  )
}

export default DocumentationBox