import React, { useState,useEffect, useContext }  from "react";
import { Link } from "react-router-dom";
import { OrderContext } from "../../contexts/OrderContext";
import axios from "axios";

function ServiceThirdStep(props) {
    const { serviceInfo } = props;

    const [searchLoading, setSearchLoading] = useState(false);
    const [order,setOrder] = useContext(OrderContext);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    
    useEffect(() => {
        if(order.orderCreated === false){
            setSearchLoading(true);
            axios.post('https://api.oggicontrollocasa.it/api/v1/order/new',{
                product:order.product,
                extra:JSON.stringify(order.extra),
                fields:JSON.stringify(order.fields),
                billing:JSON.stringify(order.billing),
            },{
                headers: { Authorization: `Bearer ${userInfo.token}`
            }
            }).then((res) => {
                setOrder({
                    currentStep:3,
                    orderCreated:true,
                    orderId: res.data.data.id,
                });
            })
            setSearchLoading(false);
        }
        
    },[]);

    useEffect(() => {
        //alert("Ordine Creato!");   
    },[order.orderCreated]);


    return (
        <>
            <div className="document-right-area" >
                <div class="p-3 pb-5">
                    <h4>Ordine Creato!</h4>
                    {   
                        searchLoading && <div class="spinner-border" role="status"></div> 
                    }
                    
                    <div class="d-grid gap-2 pt-2">
                        <Link to={"/account/ordine/"+order.orderId} class="btn btn-primary" type="button">Vai alla pagina di pagamento</Link>
                    </div>
                    

                </div>
                            
                <table class="table">
                    <tbody>
                        {
                            serviceInfo.extra && 
                            serviceInfo.extra.map((item,i) => {

                                return (
                                     <>
                                        <tr style={{background: "#f5f5f5"}}>
                                            <td>{item.name}</td>
                                            <td>{item.cost} €</td>
                                        </tr>
                                    </>
                                );
                                

                            })

                        }

                        <tr style={{color:"white",background: "#020613"}}>
                            <td>Totale</td>
                            <td>
                                {searchLoading ? 
                                    <div class="spinner-border" role="status"></div> 
                                    : 
                                    new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(order.total)
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
   
                            
                
                            
            </div>
        </>
    );
}

export default ServiceThirdStep;
