import React, { useState,useContext }  from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";

function RegisterPage() {
  const [searchLoading, setSearchLoading] = useState(false);
  const [responseResult, setResponseResult] = useState({type: "none", message: ""});
  const [accountEmail, setAccountEmail] = useState("");
  const [accountPassword, setAccountPassword] = useState("");
  const [accountConfirmPassword, setAccountConfirmPassword] = useState("");
  const [auth, setAuth] = useContext(AuthContext);
  const navigate = useNavigate();

    const handleSubmit = (e) => {
      e.preventDefault();
      setSearchLoading(true);
      
      axios.post("https://api.oggicontrollocasa.it/api/v1/register",{
        email:accountEmail,
        password:accountPassword,
        confirmPassword:accountConfirmPassword
      }).then((res) => {

        if(res.data.success){
          setResponseResult({type: "success", message: "Account creato correttamente"});

          const userInfo = {
            email:accountEmail,
            token:res.data.data.token,
          };

          localStorage.setItem("userInfo",JSON.stringify(userInfo));

          setTimeout(() => {
            navigate('/');
          }, 1000);
        }else{
          setResponseResult({type: "error", message: res.data.message});
        }


        setSearchLoading(false);
      });

    };

    const onVerifyCaptcha = (token) => {
      console.log("Verified" + token)
    }
    //<hCaptcha sitekey="00000000-0000-0000-0000-000000000000" onVerify={onVerifyCaptcha}/>
    return (
        <>
          <div className="sign-up mt-100 pb-100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-12 col-sm-12 col-12">
                  <div className="input-card-wrap text-center">
                    <div className="input-card-title">
                      {
                        responseResult.type == "error" &&
                        

                          <div class="alert alert-danger" role="alert">
                            {responseResult.message}
                          </div>
                      }
                      {
                        responseResult.type == "success" &&

                        <div class="alert alert-success" role="alert">
                          {responseResult.message}
                        </div> 
                      }

                      <h2>Nuovo Account</h2>
                      <p>
                        Hai già un account?
                        <Link
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                          to={`${process.env.PUBLIC_URL}/accedi`}
                        >
                          Accedi
                        </Link>
                      </p>
                    </div>
                    <div className="input-card-box mt-50">
                      <form>

                        <input type="email" onChange={(e) => setAccountEmail(e.target.value)} placeholder="Inserisci la tua email" />
                        
                        <input type="password" onChange={(e) => setAccountPassword(e.target.value)} style={{marginTop: 25}} placeholder="Password" />
                        <input type="password" onChange={(e) => setAccountConfirmPassword(e.target.value)} style={{marginTop: 25}} placeholder="Conferma password" />

                        {
                          !searchLoading ? 
                          (
                            <button className="mt-50" onClick={handleSubmit}>Prosegui</button>
                          )
                          :
                          (
                            <button className="mt-50" disabled><div class="spinner-border text-dark" role="status"></div></button>
                          )
                          
                        }
                      </form>
                      
                      <p className="mt-50">
                        Premendo il tasto "Prosegui" accetti le condizioni di servizio e privacy.
                        <br/>
                        <Link to={"#"}>Terms &amp; Conditions</Link>,
                        <Link to={"#"}>Privacy &amp; Policy</Link>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}

export default RegisterPage;
