import React, { useState,useEffect, useContext }  from "react";
import { OrderContext } from "../../contexts/OrderContext";
import axios from "axios";

function ServiceSecondStep(props) {
    const { serviceInfo } = props;

    const [name,setName] = useState(null);
    const [vat,setVat] = useState(null);
    const [address,setAddress] = useState(null);
    const [city,setCity] = useState(null);
    const [province,setProvince] = useState(null);
    const [cap,setCap] = useState(null);
    const [email,setEmail] = useState(null);
    const [phone,setPhone] = useState(null);

    const [order,setOrder] = useContext(OrderContext);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));


    const validateBillingInfo = () => {
        if(name === null || name === '' || vat === null || vat === '' || address === null || address === ''){
            return false;
        }

        if(city === null || city === '' || province === null || province === '' || cap === null || cap === ''){
            return false;
        }

        if(email === null || email === '' || phone === null || phone === ''){
            return false;
        }

        return true;
    }

    const handleNextStep = (e) => {
        e.preventDefault();

        if(!validateBillingInfo()){
            alert('Devi compilare tutti i campi per poter continuare');
            return;
        }

        setOrder({
            currentStep:3,
            product: order.product,
            extra:order.extra,
            fields:order.fields,
            billing: {
                name: name,
                vat: vat,
                address: address,
                city: city,
                province: province,
                cap: cap,
                email: email,
                phone: phone
            },
            orderCreated:false,
            total:order.total
        });
    }


    useEffect(() => {
        axios.get('https://api.oggicontrollocasa.it/api/v1/account/fatturazione',{
            headers: { Authorization: `Bearer ${userInfo.token}` }
        }).then((res) => {
           
            setName(res.data.data.intestazione);
            setVat(res.data.data.piva);
            setAddress(res.data.data.indirizzo);
            setCity(res.data.data.comune);
            setProvince(res.data.data.provincia);
            setCap(res.data.data.cap);
            setEmail(res.data.data.email);
            setPhone(res.data.data.telefono); 
        })
    },[]);


    return (
        <>
            <div className="document-right-area" >
                <form onSubmit={handleNextStep} id="formSecondStep">
                    <div class="p-3 pb-5">
                        <h4>Fatturazione</h4>

                        <div class="row pb-3">
                            <div class="col-md-6 pb-2">
                                <label style={{color:"black"}}>Intestazione*</label>                                           
                                <input type="text" class="form-control" onChange={(e) => setName(e.target.value)} placeholder="Nome Cognome/ Società srl" value={name} required/>
                            </div>

                            <div class="col-md-6 pb-2">
                                <label style={{color:"black"}}>P.IVA/C.F.*</label>                                           
                                <input type="text" class="form-control" onChange={(e) => setVat(e.target.value)} placeholder="P.IVA/C.F." value={vat} required/>
                            </div>
                        </div>

                        <div class="row pb-3">
                            <div class="col-md-12 pb-2">
                                <label style={{color:"black"}}>Indirizzo*</label>                                           
                                <input type="text" class="form-control" onChange={(e) => setAddress(e.target.value)} placeholder="Indirizzo" value={address} required/>
                            </div>
                        </div>

                        <div class="row pb-3">
                            <div class="col-md-4 pb-2">
                                <label style={{color:"black"}}>Comune*</label>                                           
                                <input type="text" class="form-control" onChange={(e) => setCity(e.target.value)} placeholder="Comune" value={city} required/>
                            </div>

                            <div class="col-md-4 pb-2">
                                <label style={{color:"black"}}>Provincia*</label>                                           
                                <input type="text" class="form-control" onChange={(e) => setProvince(e.target.value)} placeholder="Provincia" value={province} required/>
                            </div>

                            <div class="col-md-3 pb-2">
                                <label style={{color:"black"}}>CAP*</label>                                           
                                <input type="text" class="form-control" onChange={(e) => setCap(e.target.value)} placeholder="CAP" value={cap} required/>
                            </div>
                        </div>

                        <div class="row pb-3">
                            <div class="col-md-6 pb-2">
                                <label style={{color:"black"}}>Telefono*</label>                                           
                                <input type="text" class="form-control" onChange={(e) => setPhone(e.target.value)} placeholder="Numero Cellulare" value={phone} required/>
                            </div>

                            <div class="col-md-6 pb-2">
                                <label style={{color:"black"}}>Email*</label>                                           
                                <input type="text" class="form-control" onChange={(e) => setEmail(e.target.value)} placeholder="Email (non pec)" value={email} required/>
                            </div>
                        </div>


                    </div>
                </form>  

   
                            
                <div class="d-grid gap-2 pt-2">
                    <button type="submit" form="formSecondStep" className="btn btn-dark"style={{background: "#08bdaa", borderColor: "#08bdaa",borderTopLeftRadius: "0",borderTopRightRadius: "0"}}>
                        Continua
                    </button>
                </div>
                            
            </div>
        </>
    );
}

export default ServiceSecondStep;
