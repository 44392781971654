import React, { useState,useEffect } from 'react'
import Breadcrumb from "../../../components/common/BreadcrumbSmaller";
import LeftMenuArea from "../Menu/LeftMenuArea";
import "react-modal-video/css/modal-video.css";
import axios from 'axios';


function FatturazionePage() {
  const [intestazione, setIntestazione] = useState("")
  const [piva, setPiva] = useState("")
  const [indirizzo, setIndirizzo] = useState("")
  const [comune, setComune] = useState("")
  const [provincia, setProvincia] = useState("")
  const [cap, setCap] = useState("")
  const [telefono, setTelefono] = useState("")

  const [searchResult, setSearchResult] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [responseResult, setResponseResult] = useState({type: "none", message: ""});

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {
        setSearchLoading(true);
        

        axios.get('https://api.oggicontrollocasa.it/api/v1/account/fatturazione',{
         headers: { Authorization: `Bearer ${userInfo.token}` }
        }).then((res) => {
            setIntestazione(res.data.data.intestazione)
            setPiva(res.data.data.piva)
            setIndirizzo(res.data.data.indirizzo)
            setComune(res.data.data.comune)
            setProvincia(res.data.data.provincia)
            setCap(res.data.data.cap)
            setTelefono(res.data.data.telefono)
            setSearchLoading(false);
        })
    }, []);

  const handleFatturazioneSave = (e) => {
    e.preventDefault();
    setSearchLoading(true);

    const data = {
      intestazione: intestazione,
      piva: piva,
      indirizzo: indirizzo,
      comune: comune,
      provincia: provincia,
      cap: cap,
      telefono: telefono
    }
    console.log(data)

    axios.post('https://api.oggicontrollocasa.it/api/v1/account/fatturazione/update',data,{
      headers: { Authorization: `Bearer ${userInfo.token}` }
    }).then((res) => {
      setSearchResult(res.data.data);
      if(res.data.success){
        setResponseResult({type: "success", message: "Informazioni di fatturazione modificate"});
      }else{
        setResponseResult({type: "error", message: res.data.message});
      }
      setSearchLoading(false);
    })
    
  }


  return (
    <>
      <Breadcrumb pageName="Fatturazione" pageTitle="Fatturazione" />
      <div className="document-section pt-100 pb-100">
        <div className="container-fluid">
            <div className="row">
              <LeftMenuArea />

              <div className="col-lg-9 order-lg-2 order-3">
                <h3 className="document-title mb-70">Dati di fatturazione</h3>
                <div className="document-middle-area">
                  <div className="document-item">
                    {
                      responseResult.type == "error" &&

                      <div class="alert alert-danger" role="alert">
                        {responseResult.message}
                      </div> 
                    }
                    {
                      responseResult.type == "success" &&

                      <div class="alert alert-success" role="alert">
                        {responseResult.message}
                      </div> 
                    }
                    <form
                        method="post"
                        id="contact-form"
                        className="contat-input contat-input-design-2"
                      >
                        <div className="row">
                          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>Intestazione</label>
                            <input required type="text" value={intestazione} name="intestazione" onChange={(e) => setIntestazione(e.target.value)} placeholder="Società/Nome Cognome"/>
                          </div>
                          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>P.IVA/C.F.</label>
                            <input required type="text" value={piva} name="piva" onChange={(e) => setPiva(e.target.value)} placeholder="Partita IVA o Codice Fiscale"/>
                          </div>
                          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>Indirizzo</label>
                            <input required type="text" value={indirizzo} name="indirizzo" onChange={(e) => setIndirizzo(e.target.value)} placeholder="Sede Legale/Indirizzo" />
                          </div>
                          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>Comune</label>
                            <input required type="text" value={comune} name="comune" onChange={(e) => setComune(e.target.value)} placeholder="Comune"/>
                          </div>
                          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>Provincia</label>
                            <input required type="text" value={provincia} name="provincia" onChange={(e) => setProvincia(e.target.value)} placeholder="Provincia"/>
                          </div>

                          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>CAP</label>
                            <input required type="text" value={cap} name="cap" onChange={(e) => setCap(e.target.value)} placeholder="CAP"/>
                          </div>

                          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                            <label>Telefono</label>
                            <input required type="text" value={telefono} name="telefono" onChange={(e) => setTelefono(e.target.value)} placeholder="telefono"/>
                          </div>


                          <div className="form-btn-wrap">
                            { 
                              searchLoading ? 
                              (
                                <button className="form-btn">
                                  <div class="spinner-border text-dark" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                </button>
                              ) : (
                                <button onClick={handleFatturazioneSave} className="form-btn">
                                  Salva
                                </button>
                              )
                              
                            }
                            


                          </div>

                        </div>
                      </form>


                  </div>
                
                </div>
              </div>
            
            </div>
        </div>
      </div>
    </>
  );
}

export default FatturazionePage;
