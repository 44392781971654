import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { ServiceSearchContext } from "../../contexts/ServiceSearchContext";

const initalHeaderState = { activeMenu: "", scrllTop: "" };
const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
function reducer(state, action) {
  switch (action.type) {
    case "homeOne":
      return { activeMenu: "homeOne", scrllTop: scrollTop() };
    case "docs":
      return { activeMenu: "docs", scrllTop: scrollTop() };
    case "pages":
      return { activeMenu: "pages", scrllTop: scrollTop() };
    case "topic":
      return { activeMenu: "topic", scrllTop: scrollTop() };
    case "blog":
      return { activeMenu: "blog", scrllTop: scrollTop() };
    case "contact":
      return { activeMenu: "contact", scrllTop: scrollTop() };
    default:
      return { activeMenu: "" };
  }
}
function Header() {
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-menu-area");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");
  };
  const [state, dispatch] = useReducer(reducer, initalHeaderState);
  // console.log(state);
  const [showSidebar, SetSidebar] = useState(0);
  const [mobileSidebar, SetMobileSidebar] = useState(0);
  const handleSidebar = () => {
    if (showSidebar === false || showSidebar === 0) {
      return SetSidebar(1);
    } else {
      return SetSidebar(false);
    }
  };
  const handleMobileSidebar = () => {
    if (mobileSidebar === false || mobileSidebar === 0) {
      SetMobileSidebar(1);
    } else {
      SetMobileSidebar(false);
    }
  };

  const [auth] = useContext(AuthContext);
  const [searchServiceName, setSearchServiceName] = useContext(ServiceSearchContext);

  return (
    <>
      <header>
        <nav>
          <div className="header-menu-area header-menu-area-design-2">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2 col-sm-6 col-6">
                  <div className="logo text-left">
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/logo-2.png"}
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-sm-6 col-6">
                  <Link
                    to={"#"}
                    onClick={handleMobileSidebar}
                    className={
                      mobileSidebar === 1
                        ? "hidden-lg hamburger h-active"
                        : "hidden-lg hamburger"
                    }
                  >
                    <span className="h-top" />
                    <span className="h-middle" />
                    <span className="h-bottom" />
                  </Link>
                  <nav
                    className={
                      mobileSidebar === 1 ? "main-nav slidenav" : "main-nav"
                    }
                  >
                    <div className="logo mobile-ham-logo d-lg-none d-block text-left">
                      <Link onClick={scrollTop} to={"#"}>
                        <img
                          src={process.env.PUBLIC_URL + "/images/logo.png"}
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <ul>

                      <li><Link onClick={() => setSearchServiceName("visure")} to={"/servizi"}>Visure</Link></li>
                      <li><Link onClick={() => setSearchServiceName("catasto")} to={"/servizi"}>Catasto</Link></li>
                      <li><Link onClick={() => setSearchServiceName("urbanistica")} to={"/servizi"}>Urbanistica</Link></li>
                      <li><Link onClick={() => setSearchServiceName("camera")} to={"/servizi"}>Camera di Commercio</Link></li>
                      <li><Link onClick={() => setSearchServiceName("pra")} to={"/servizi"}>P.R.A.</Link></li>
                      <li><Link onClick={() => setSearchServiceName("consulenze")} to={"/servizi"}>Consulenze</Link></li>
                    </ul>

                    { !auth ?
                      (
                        <div className="menu-btn-wrap d-block d-lg-none">
                          <Link className="common-btn btn-style-1" to={"/accedi"}>
                            Accedi
                          </Link>
                        </div>
                      ):(
                        <div className="menu-btn-wrap d-block d-lg-none">
                          <Link className="common-btn btn-style-1" to={"#"}>
                            Account
                          </Link>
                        </div>
                      )
                      
                    }
                    


                  </nav>
                </div>
                <div className="col-xl-2 col-lg-2 d-none d-lg-block">
                  <div className="menu-btn-wrap">
                    { !auth ?
                      (
                        <Link className="common-btn btn-style-6" to={"/accedi"}>
                          Accedi
                        </Link>
                      ):(
                        <Link className="common-btn btn-style-6" to={"/account/dashboard"}>
                          Account
                        </Link>
                      )
                      
                    }
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        
      </header>
    </>
  );
}

export default Header;
